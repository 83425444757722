<template>
    <div>
        <div v-if="page==0">
            <h6>รายการแจ้งเตรียมผลิต</h6>
            <div class="d-flex justify-content-between">
                <div class="d-flex">
                    <div class="p-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text">ค้นหา</span>
                            <input type="text" class="form-control" placeholder="เลขที่/ใบสั่งผลิต" v-model="selPrepareData">
                        </div>
                    </div>
                    <div  class="p-2">
                        <div class="input-group input-group-sm">
                            <span class="input-group-text">จัดเรียงตาม</span>
                            <select class="form-control" v-model="selOrder"  @change="getProductionList()">
                                <option v-for="(item, index) in orderBy" :value="item.sql" :key="index">
                                {{ item.detail }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="p-2">
                    <button type="button" @click="page=1" class="btn btn-outline-primary btn-sm"><i class="bi bi-file-earmark-plus"></i> สร้างใบแจ้งเตรียมวัตุถดิบ</button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-bordered">
                    <thead>
                    <tr>
                        <th>ลำดับ</th>
                        <th>เลขที่</th>
                        <th>ใบสั่งผลิต</th>
                        <th>วันที่สั่งผลิต</th>                        
                        <th>กำหนดส่ง</th>
                        <th>ชื่อสินค้า</th>
                        <th>สถานะ</th>
                        <th>รายละเอียด</th>
                        <th>ลบ</th>
                    </tr>
                    </thead>
                    <tbody> 
                    <tr v-for="(item,index) in searchPrepareData" :Key="index">
                        <td>{{ index+1 }}</td>
                        <td>{{ item.doc_id }}</td>
                        <td>{{ item.production_id }}</td>
                        <td>{{ item.order_date }}</td>
                        <td>{{ item.delivery_date }}</td>
                        <td style="text-align: left;">{{ item.product_name }}</td>
                        <td>{{ item.progress_name }}</td>
                        <td><button type="button" class="btn btn-outline-success btn-sm" @click="showDetail1(item.doc_id)">รายละเอียด</button></td>
                        <td><button :disabled="item.progress>='3'" type="button" class="btn btn-outline-danger btn-sm" @click="removeData(item.doc_id)"><i class="bi bi-trash"></i></button></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        {{ prepareDataList }}
    </div>
</template>
<script>
import axios from 'axios'
export default{
    name:'ProductExport',
    data(){
        return{
            prepareDataList:[],
            page:0,
            orderBy:[
                {"detail":"เลขที่ น้อยไปมาก", "sql":"doc_id ASC"},
                {"detail":"เลขที่ มากไปน้อย", "sql":"doc_id DESC"},
                {"detail":"ใบสั่งผลิต น้อยไปมาก", "sql":"production_id ASC"},
                {"detail":"ใบสั่งผลิต มากไปน้อย", "sql":"production_id DESC"},
            ],
            selOrder:'doc_id ASC',
        }
    },
    props:{
        user: String
    },
    methods:{
        getProductionList(){
            axios.post("https://www.tdsi-demo.com/api/api.php", {
            action: "planning_prepare",
            }).then((res) => {
                if (res.status == 200) {
                    this.prepareDataList = res.data
                }
            }).catch(function (error) {
                console.log(error);
                alert(error)
            });
        },

    },
    created(){
        this.getProductionList()
    },
    computed:{
        searchPrepareData:function(){
            return this.prepareDataList.filter((List)=>{
                return List.doc_id.match(this.selPrepareData) || List.production_id.match(this.selPrepareData)
            })
        }
    }
}
</script>
<style scoped>
.table-responsive {
    margin: 10px;
    max-height:70vh;
    font-size: 10pt
}
.table-responsive tbody tr:hover {
    background-color: lightgray;
}
</style>
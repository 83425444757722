<template>
    <div>
        <nav class="navbar navbar-expand-sm navbar-dark bg-dark fixed-top">
            <div class="container-fluid">
                <img class="navbar-brand" src="../assets/logo.png" height="50px">
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#mynavbar">
                <span class="navbar-toggler-icon"></span>
                </button>
                <div class="collapse navbar-collapse" id="mynavbar">
                    <ul class="navbar-nav me-auto">
                        <li class="nav-item" v-for="(item,index) in menuList" :key="index" style="cursor: pointer;">
                        <a class="nav-link active" @click="menuClick(index)" v-if="index==activeStatus">{{ item }}</a>
                        <a class="nav-link" v-else @click="menuClick(index)">{{ item }}</a>
                        </li>
                        <!-- <li class="nav-item">
                        <a class="nav-link" href="javascript:void(0)">Link</a>
                        </li>
                        <li class="nav-item">
                        <a class="nav-link" href="javascript:void(0)">Link</a>
                        </li> -->
                    </ul>
                    <div class="d-flex">
                        <a class="nav-link mx-3" style="color: white;">{{ infoList.dept }}</a>
                        <a class="nav-link mx-3" style="color: white;">{{ infoList.user }}</a>
                        <img class="navbar-brand mx-3" src="../assets/logout.png" height="30px" style="filter: invert(100%); cursor: pointer;">
                    </div>
                </div>
            </div>
        </nav>
        

    </div>
</template>
<script>
export default{
    name:'navBar',
    data(){
        return{
            activeStatus:0,
        }
    },
    props:{
        menuList: Array,
        infoList: Array,
    },
    methods:{
        menuClick(i){
            this.activeStatus = i
            this.$emit("menuSelect",i)
        },
    }
}
</script>
<style scoped>

</style>
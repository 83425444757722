<template>
    <div>
        <div class="navTab">
            <NavBar :menuList="menuList" :infoList="infoList" @menuSelect="menuSelect"/>
        </div>
        <div v-if="menuPage==0">
            <ProductPrepare :user="infoList.user"/>
        </div>
        
    </div>
</template>
<script>
import NavBar from './NavBar.vue'
import ProductPrepare from './ProductPrepare.vue'
export default{
    name:'MainPage',
    components: { NavBar, ProductPrepare },
    data(){
        return{
            menuList:[
                "รายการแจ้งเตรียมผลิต", "รายการแจ้งเบิกจ่ายบรรจุภัณฑ์", "รายการสินค้า", "รายการบรรจุภัณฑ์", "รายการบรรจุภัณฑ์ย่อย", "รายชื่อลูกค้า", "รายชื่อผู้ใช้งาน", "รายงาน"
            ],
            infoList:{
                dept:"แผนกวางแผน",
                user:"นาย SIP วางแผน"
            },
            menuPage:0,
        }
    },
    methods:{
        menuSelect(i){
            this.menuPage = i
        }
    },

}
</script>
<style scope>
.navTab{
    height: 10vh;
}
</style>
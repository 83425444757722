<template>
  <div id="app">
    <MainPage/>
  </div>
</template>

<script>

import MainPage from './components/MainPage.vue'

export default {
  name: 'App',
  components: {
    MainPage
  }
}
</script>

<style>
#app {
  font-family: 'Sarabun', sans-serif;
  text-align: center;
  font-size: 10pt;
}
@font-face {
  font-family: "Sarabun";
  src: local("Sarabun"),
  url(./fonts/Sarabun-Regular.ttf);
}
</style>
